<template>
  <main>
    <h1 class="headline blue--text text-center mt-4 mb-3">Puuttuvat minuutit</h1>
    <v-divider></v-divider>
    <v-card class="mt-8" v-if="!apiCall">
      <v-card-text>
        <v-simple-table>
          <thead>
          <tr>
            <td><b>Aika</b></td>
            <td><b>Suljettu</b></td>
            <td><b>Pituus</b></td>
            <td><b>Sovellus</b></td>
            <td><b>CDR ID Sisään</b></td>
            <td><b>CDR ID Ulos</b></td>
          </tr>
          </thead>
          <tbody>
            <tr v-for="d in resources" :key="d.call_date">
              <td>{{ d.call_date }}</td>
              <td>{{ d.hangup_date }}</td>
              <td>{{ d.duration }}</td>
              <td>{{ d.app_data }}</td>
              <td><b style="color:orange;cursor:pointer" @click="seeEvents(d.cdr_id_inbound)">{{ d.cdr_id_inbound }}</b></td>
              <td><b style="color:orange;cursor:pointer" @click="seeEvents(d.cdr_id_outbound)">{{ d.cdr_id_outbound }}</b></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <!-- events dialog -->
    <v-dialog
        v-model="dialog"
        max-width="1200"
    >
      <v-card>
        <v-card-text v-if="selected_event">
          <template v-if="selected_event_call_details.length > 0">
            Puhelun tunniste: {{ this.selected_event_call_details[0].call_unique_id }}<br/>
          </template>
          Soittaja: {{ this.selected_event.consumer.identification }} ({{ this.selected_event.consumer.consumer_operator }})<br/>
          Palvelu: {{ this.selected_event.service.name }}<br/>
          Numero: {{ this.selected_event.number.number }}<br/>
          Puhelun kesto: {{ this.selected_event.cdr.duration_string }}<br/>
          Laskutuskesto: {{ this.selected_event.cdr.billsec_string }}<br/>
        </v-card-text>

        <template v-if="selected_event_call_details.length > 0">
          <v-card-title>
            Tapahtumat
          </v-card-title>

          <v-simple-table>
            <thead>
            <tr>
              <td>
                Puhelukomponentti
              </td>
              <td>
                Aika
              </td>
              <td>
                Tapahtuma
              </td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in selected_event_call_details" :key="item.id">
              <td>
                {{ item.component }}
              </td>
              <td>
                {{ item.added_time }}
              </td>
              <td>
                {{ item.info }}
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </template>
        <v-card-title v-else>
          Ei tapahtumia
        </v-card-title>
      </v-card>
    </v-dialog>
  </main>
</template>
<script>

export default {
  data() {
    return {
      apiCall: true,
      resources: [],
      selected_event_call_details: {},
      dialog: false,
    }
  },
  methods: {
    seeEvents(selected_event){
      this.selected_event_call_details = {}
      this.axios.get('reports/call_details?cdr_id='+selected_event).then((r) => {
        this.selected_event_call_details = r.data
        this.dialog = true
      })
    }
  },
  mounted(){
    this.axios.get('reports/missing_agent_minutes').then((r)=>{
      this.resources = r.data
      this.apiCall = false
    })
  }
}
</script>

<style scoped>

</style>